import React, { useState, useEffect } from 'react';
import { PDFDownloadLink, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'Helvetica',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/helvetica/v9/si6a1af1d632d1517c1491d35159c2e7.woff2' }, // normal
    { src: 'https://fonts.gstatic.com/s/helveticabold/v6/5PZ1owUeDA7Af4l-Uel3etU9oM.woff2', fontWeight: 'bold' }, // bold
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'Helvetica',
  },
  columnPart: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  rowPart: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  heading: {
    fontSize: 18,
    marginBottom: 10,
  },
  billTo: {
    height: 100,
  },
  billToLabel: {
    fontSize: 10,
    color: '#777',
    marginBottom: 5,
  },
  billToInfo: {
    fontSize: 10,
    marginBottom: 2,
  },
  invoiceDetails: {
    textAlign: 'right',
    flex: 1,
  },
  invoiceDetail: {
    fontSize: 10,
    marginBottom: 2,
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    borderTop: '1px solid #000',
    paddingTop: 10,
    fontSize: 10,
  },
  footerText: {
    fontSize: 10,
  },
  footerTextBold: {
    fontSize: 12,
    fontFamily: 'Helvetica',
    fontWeight: 'bold'
  },
  contactInfo: {
    textAlign: 'right',
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#bdbdbd",
  },
  tableColHeaderWider: {
    width: "80%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#bdbdbd",
  },
  tableColRight: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "right"
  },
  tableColWider: {
    width: "80%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 7,
    fontSize: 10,
    fontFamily: 'Helvetica',
    fontWeight: 'bold'
  },
  tableCell: {
    margin: 7,
    fontSize: 10,
  },
  tableCellBold: {
    margin: 7,
    fontSize: 10,
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
  },
  main: {
    display: 'block',
    position: 'absolute',
    top: 210,
    left: 30
  },
  padTop: {
    marginTop: 20
  },
  topLeft: {
    flex: 1,
  }
});

const InvoiceDocument = ({ invoiceData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.columnPart}>
        <View style={styles.rowPart}>
          <View style={styles.invoiceTopLeft}>
            <Text style={styles.heading}>Invoice</Text>
            <Text style={styles.billToLabel}>Thank You For Your Continued Business</Text>
            <Text style={styles.footerText}>KSCHW LLC</Text>
            <Text style={styles.footerText}>Darren Kellenschwiler</Text>
            <Text style={styles.footerText}>5900 Balcones Drive</Text>
            <Text style={styles.footerText}>Austin, Texas 78731, USA</Text>
          </View>
          <View style={styles.invoiceDetails}>
            <Text style={styles.invoiceDetail}>{invoiceData.invoiceDate}</Text>
            <Text style={styles.invoiceDetail}>{invoiceData.invoiceNumber}</Text>
            <Text style={styles.invoiceDetail}>Service Period: {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}</Text>
          </View>
        </View>
        <View style={styles.billTo} >
          <Text style={styles.billToLabel}>Bill To</Text>
          <Text style={styles.billToInfo}>BSV Association</Text>
          <Text style={styles.billToInfo}>Grafenauweg 6</Text>
          <Text style={styles.billToInfo}>CH-6300 Zug, Switzerland</Text>
        </View>
      </View>

      <View style={styles.main}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeaderWider}>
              <Text style={styles.tableCellHeader}>Item</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Amount</Text>
            </View>
          </View>
          {invoiceData.items.map((item, index) => {
            if (item.amount === 0) return null;
            return (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableColWider}>
                  <Text style={styles.tableCellBold}>{item.item}</Text>
                  {!!item.description && <Text style={styles.tableCell}>{item.description}</Text>}
                </View>
                <View style={styles.tableColRight}>
                  <Text style={styles.tableCell}>{item.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Text>
                </View>
              </View>
            )}
          )}
          <View style={styles.tableRow}>
            <View style={styles.tableColWider}>
              <Text style={styles.tableCell}>TOTAL (USD)</Text>
            </View>
            <View style={styles.tableColRight}>
              <Text style={styles.tableCell}>{invoiceData.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Text>
            </View>
          </View>
        </View>
        <View style={styles.padTop}>
          <Text style={styles.billToLabel}>Payment due by {invoiceData.paymentDue}</Text>
          <Text style={styles.footerText}>Beneficiary Bank Name: Community Federal Savings Bank</Text>
          <Text style={styles.footerText}>Beneficiary Bank Address: 89-16 Jamaica Ave, Woodhaven NY 11421, United States</Text>
          <Text style={styles.footerText}>Beneficiary Bank Account No.: 822000351712</Text>
          <Text style={styles.footerText}>SWIFT/BIC: CMFGUS33</Text>
          <Text style={styles.footerText}> </Text>
          <Text style={styles.footerText}>Account Holder Name: KSCHW LLC</Text>
          <Text style={styles.footerText}>Account Holder Address: 5900 Balcones Drive, Austin, Texas 78731, USA</Text>
          <Text style={styles.footerText}>Personal Account No.: 822000351712</Text>
          <Text style={styles.footerText}>ABA No.: 026073150</Text>
          <Text style={styles.footerText}>ACH No.: 026073150</Text>
          <Text style={styles.footerText}>Account Currency: USD</Text>
        </View>
      </View>
      <View style={styles.footer}>
        <View style={styles.contactInfo}>
          <Text style={styles.footerText}>+1 804 814 3723</Text>
          <Text style={styles.footerText}>d.kellenschwiler@bsvblockchain.org</Text>
        </View>
      </View>
    </Page>
  </Document>
);

const App = () => {
  const [unauthorized, setUnauthorized] = useState(true);
  useEffect(() => {
    if (localStorage.getItem('auth') === process.env.REACT_APP_AUTH) {
      setUnauthorized(false)
    }
  }, []);

  const [items, setItems] = useState([
    {
      item: "Technical Lead of Utilization - " + new Date().toLocaleString('default', { month: 'long', year: 'numeric' }),
      description: "Maintain an understanding of the current ecosystem’s technology problems, working solutions, future proposals, and level of adoption. Consume nChain’s public patents and white papers to ensure a strong working knowledge of how things are supposed to work in Bitcoin. Work across internal and external projects to guide the development of key libraries, tools, and infrastructure components in line with the original Bitcoin protocol, as guided by the background knowledge, and an awareness of the current state of play. Duties range from defining the overall technical roadmap to achieving unbounded scalability, to hands on coding of pull requests to core libraries used across the ecosystem. Communicating the roadmap to colleagues clearly with illustrations and documentation, as well as iteratively improving the roadmap as it is completed. Assisting in the construction of a technical team which can deliver the requirements of the technical roadmap. There also has to be an understanding of the Bitcoin system as a whole, how applications rely on the system and what therefore needs to be protect in terms of changing functionality associated with Teranode and its impact on the topology of the network.",
      amount: 15964.00,
    },
    {
      item: "Expenses - Report Attached",
      description: "",
      amount: 0.00,
    },
  ]);

  const generateRandomBase58 = (length) => {
    const characters = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
    let result = 'Inv';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };

  const [invoiceData, setInvoiceData] = useState({
    invoiceNumber: generateRandomBase58(7),
    invoiceDate: new Date().toISOString().split('T')[0],
    paymentDue: new Date(new Date().setDate(new Date().getDate() + 30)).toISOString().split('T')[0],
    items: items,
    total: items.reduce((sum, item) => sum + item.amount, 0),
  });

  const handleAmountChange = (index, value) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, amount: parseFloat(value) } : item
    );
    setItems(updatedItems);
    setInvoiceData({
      ...invoiceData,
      items: updatedItems,
      total: updatedItems.reduce((sum, item) => sum + item.amount, 0).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    });
  };

  if (unauthorized) return <></>
  return (
    <div style={{ width: '80%', padding: '5%' }}>
      <h1 style={{ fontSize: 18 }}>Create Invoice</h1>
      <table style={{ width: '100%', padding: '15px 0', bottom: 20, lineHeight: 2 }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Item</th>
            <th style={{ textAlign: 'right' }}>Amount</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>{item.item}</td>
              <td  style={{ textAlign: 'left' }}>
                <input
                  type="number"
                  style={{ padding: 5, fontSize: 16, width: '100%' }}
                  value={item.amount}
                  onChange={(e) => handleAmountChange(index, e.target.value)}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td>Total (USD)</td>
            <td style={{ textAlign: 'right' }}>{invoiceData.total.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}</td>
          </tr>
        </tbody>
      </table>
      <PDFDownloadLink
        document={<InvoiceDocument invoiceData={invoiceData} />}
        fileName={`${new Date().toISOString().split('T')[0].split('-').slice(0,2).join('')}_Darren Kellenschwiler_Invoice_BSVA.pdf`}
      >
        {({ loading }) => (loading ? 'Generating PDF...' : 'Download PDF')}
      </PDFDownloadLink>
    </div>
  );
};

export default App;